@tailwind base;
@layer base {
    h1 {
      @apply text-2xl;
    }
    h2 {
      @apply text-xl;
    }
    h3 {
      @apply text-lg;
    }
  }
@tailwind components;
@tailwind utilities;

.fdlt-gradient{
    @apply bg-gradient-to-r from-[#05c46b] to-[#9DDD8F]
    ;
}

.fdlt-gradient-text{
    background: linear-gradient(to left,#05c46b, #9DDD8F);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color : #9DDD8F;
}
.fdlt-btn{
    @apply bg-gray-900 text-base-100 shadow border-gray-800
    ;
}
.btn {
    @apply text-base-100
    ;
  }
.fdlt-btn:hover{
    @apply bg-gray-700 border-gray-800
    ;
}
.fdlt-btn:focus{
    @apply bg-base-300 border-gray-900 text-gray-900
    ;
}
.fdlt-barnav-active{
    @apply inline-flex p-4 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active
    ;
}
.fdlt-barnav-active:dark{
    @apply text-blue-500 border-blue-500
    ;

}
.fdlt-barnav-inactive{
    @apply inline-flex p-4 rounded-t-lg border-b-2 border-transparent
    ;
}
.fdlt-barnav-inactive:hover{
    @apply text-gray-600 border-gray-300
    ;
}
.fdlt-barnav-inactive:dark{
    @apply hover:text-gray-300
    ;
}

.moda {
    transition: opacity 0.25s ease;
}
body.modal-active {
    overflow-x: hidden;
    overflow-y: visible !important;
}

[x-cloak] { display: none !important; }
